import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`SVG images are an integral part of modern web design. SVG, short for `}<ItalicText mdxType="ItalicText">{`Scalable Vector Graphics`}</ItalicText>{`,
are highly scalable and have a small file size. You can also customize them with CSS, as I'll show you.`}</p>
    <p>{`In general, you can embed an SVG image in a website using the `}<InlineCode mdxType="InlineCode">{`<`}{`img`}{`>`}</InlineCode>{` tag or the CSS
property `}<InlineCode mdxType="InlineCode">{`background-image`}</InlineCode>{`. In this case, you won't be able to make major style changes to the
image. I prefer to use inline SVG images instead. They can be customized and even animated with CSS.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwACBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB5lNbFtSP/8QAGRAAAgMBAAAAAAAAAAAAAAAAARIAAhEh/9oACAEBAAEFAk4iTBMJvhN0An//xAAWEQEBAQAAAAAAAAAAAAAAAAAAIQH/2gAIAQMBAT8BmK//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFH/8QAHBAAAgICAwAAAAAAAAAAAAAAAAECIRAREjFB/9oACAEBAAY/AnH0hHksOy9Uds//xAAaEAACAwEBAAAAAAAAAAAAAAABIQARQTEQ/9oACAEBAAE/IQRXxGAZ0VniKMaNQ7Qlzkh//9oADAMBAAIAAwAAABDML//EABYRAQEBAAAAAAAAAAAAAAAAABEAAf/aAAgBAwEBPxDAJn//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QdWxf/8QAHhABAAICAQUAAAAAAAAAAAAAAREhAEFxMVFhgaH/2gAIAQEAAT8QPTTB1zxi3G9KpQWy4hpwWOS5Ke9ej7gqECqSp0Yoteaz/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Woman painting a cat figure on a piece of paper.",
          "title": "Woman painting a cat figure on a piece of paper.",
          "src": "/static/1513877f07e4e3069f3b3e975b2aef6d/e5166/pexels-andrea-piacquadio-painter.jpg",
          "srcSet": ["/static/1513877f07e4e3069f3b3e975b2aef6d/f93b5/pexels-andrea-piacquadio-painter.jpg 300w", "/static/1513877f07e4e3069f3b3e975b2aef6d/b4294/pexels-andrea-piacquadio-painter.jpg 600w", "/static/1513877f07e4e3069f3b3e975b2aef6d/e5166/pexels-andrea-piacquadio-painter.jpg 1200w", "/static/1513877f07e4e3069f3b3e975b2aef6d/b17f8/pexels-andrea-piacquadio-painter.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Andrea Piacquadio / pexels.com`}</em></p>
    <p>{`How can you embed inline SVG images? What are best practices to customize them with CSS properties and variables? Read on
to find out more.`}</p>
    <h2>{`How to embed an inline SVG`}</h2>
    <p>{`SVG images can be embedded directly in the HTML document using the `}<InlineCode mdxType="InlineCode">{`<`}{`svg`}{`>`}</InlineCode>{` tag. Simply
open the image file in the code editor of your choice, copy the code, and paste it into your HTML template. Here's an example:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<svg height="48" width="48" viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
    <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
</svg>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Nowadays, websites are often built with component-based JavaScript frameworks like `}<a parentName="p" {...{
        "href": "https://angular.io/"
      }}>{`Angular`}</a>{` or
`}<a parentName="p" {...{
        "href": "https://react.dev/"
      }}>{`React`}</a>{`. These allow you to wrap an inline SVG in its own component that you can easily reuse in different
sections of your website. One example for this pattern is the `}<a parentName="p" {...{
        "href": "https://mui.com/material-ui/material-icons/"
      }}>{`Material UI icon library`}</a>{`.`}</p>
    <p>{`The UI library `}<a parentName="p" {...{
        "href": "https://material.angular.io/components/icon/overview#svg-icons"
      }}>{`Angular Material`}</a>{` even goes a step further:
You can add SVG files as assets to your website. The application later fetches the images from the server, allowing the SVG code
to be embedded in the HTML document via the `}<InlineCode mdxType="InlineCode">{`<`}{`mat-icon`}{`>`}</InlineCode>{` component.`}</p>
    <h2>{`Demo: Flexible styling of SVG Icon Buttons`}</h2>
    <p>{`I've created a `}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/fancy-css-playground/#/inlinesvg"
      }}>{`demo`}</a>{` with use cases for inline SVGs. You can
customize the color of the icons through a color picker element:`}</p>
    <iframe src="https://alexlehner86.github.io/fancy-css-playground/#/inlinesvg" title="Demo: Flexible inline SVG styling" loading="lazy"></iframe>
    <p>{`I accomplished this by linking the SVG image's `}<InlineCode mdxType="InlineCode">{`fill`}</InlineCode>{` to the CSS property `}<InlineCode mdxType="InlineCode">{`color`}</InlineCode>{`.`}</p>
    <h2>{`Customizing the SVG with CSS`}</h2>
    <p>{`First, you set the SVG image's `}<InlineCode mdxType="InlineCode">{`fill`}</InlineCode>{` property to the value `}<InlineCode mdxType="InlineCode">{`currentColor`}</InlineCode>{`. This
special keyword represents the value of an element's `}<InlineCode mdxType="InlineCode">{`color`}</InlineCode>{` CSS property. This way, the SVG image's
vectors will be filled with the element's font color. You can set the property in the SVG code:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<svg fill="currentColor" height="48" width="48" viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
    <path d="..."></path>
</svg>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Or, you can set the property in your CSS code:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.icon svg {
    fill: currentColor;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Now, you can control the appearance of the SVG image through the `}<InlineCode mdxType="InlineCode">{`color`}</InlineCode>{` CSS property. This makes it
easier to manage color themes and different states of controls.`}</p>
    <p>{`Imagine you have a button with an icon and text, like in my demo above: You can color all elements inside the button with a
single CSS rule. You can easily style different states like `}<InlineCode mdxType="InlineCode">{`:hover`}</InlineCode>{`, `}<InlineCode mdxType="InlineCode">{`:focus`}</InlineCode>{`,
or `}<InlineCode mdxType="InlineCode">{`:disabled`}</InlineCode>{`. And you can even animate the color change:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.primary-btn {
    color: #f118a6;
    transition: color 0.5s ease;
}
.primary-btn:hover, .primary-btn:focus {
    color: #920c64;
    outline: 2px solid currentColor;
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Use CSS Variables for Multi-Colored Images`}</h2>
    <p>{`You can even use `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties"
      }}>{`CSS variables`}</a>{` inside SVG code.
This can be useful when you're dealing with an SVG image that uses multiple colors. In this case, you can still link the main
color to the `}<InlineCode mdxType="InlineCode">{`color`}</InlineCode>{` CSS property. Then, define CSS variables for the other colors:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="..." />
    <path d="..." />
    <path fill="var(--secondary-fill)" d="..." />
</svg>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`In the example above, the first and second `}<InlineCode mdxType="InlineCode">{`path`}</InlineCode>{` elements are colored by the defined font color. The
fill of the third path can be controlled through the CSS variable `}<InlineCode mdxType="InlineCode">{`--secondary-fill`}</InlineCode>{`:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.icon {
    color: darkred;
    --secondary-fill: white;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Check out the article `}<a parentName="p" {...{
        "href": "https://frontstuff.io/multi-colored-svg-symbol-icons-with-css-variables"
      }}>{`“Multi-Colored SVG Symbol Icons with CSS Variables”`}</a>{`
for an in-depth look at the concept.`}</p>
    <h2>{`Make Your Images Accessible`}</h2>
    <p>{`Last, but not least: Don't forget about accessibility! If the SVG image is purely decorative, then
use `}<InlineCode mdxType="InlineCode">{`aria-hidden="true"`}</InlineCode>{` to hide the element from assistive technologies.`}</p>
    <p>{`If the image conveys important information, then assign `}<InlineCode mdxType="InlineCode">{`role="img"`}</InlineCode>{` and provide alternative text
through the `}<InlineCode mdxType="InlineCode">{`aria-label`}</InlineCode>{` attribute. Read `}<a parentName="p" {...{
        "href": "https://www.unimelb.edu.au/accessibility/techniques/accessible-svgs"
      }}>{`“Accessible SVGs”`}</a>{`
for more information and use cases.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      